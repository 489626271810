<template>
  <documents-component
    style="min-height: 600px"
    :is-project="true"
  ></documents-component>
</template>

<script>
import DocumentsComponent from "@/router/views/documents/index";
import { mapActions } from "vuex";
export default {
  components: {
    DocumentsComponent,
  },
  methods: {
    ...mapActions({
      getProjectFromCode: "project/getProjectFromCode",
    }),
  },
  mounted() {
    this.getProjectFromCode({
      code: this.$route.params.codigoProyecto,
    });
  },
};
</script>

<style></style>
